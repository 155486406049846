.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  .gallery-container {
    button {
      background-color: transparent;
      border: none;
    }
  }
  #content-img-gallery {
    width: 100%;
    position: absolute;
    background-position: center center;
        background-size: cover;
  }
  .container-img-gallery {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  button {
    z-index: 2000;
    font-size: 48px;
    top: 0;
  left: 0;
  position: absolute;
  color: red;
  }
  }
  /* Create four equal columns that sits next to each other */
  .column {
    flex: 33%;
    max-width: 50%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }