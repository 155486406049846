.seccion {
    height: 70rem;
    
    align-items: center;
    justify-content: center;
    text-align: center;
}

.section {
    display: flex;
    height: 70rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.contenido-centrado {
    min-width: 80rem;
}

.alinear-derecha {
    display: flex;
    justify-content: flex-end;
}