

.contact-section {
    h1 {
        margin: 0;
        
    }
    label, legend, h1,h4,h5 {
        color: white !important;
    }
}
.header {

    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 35%, rgba(0,0,0,0.20490194368763126) 100%); 
    position: absolute;
    width: 100%;
    z-index: 1000;
    &.inicio {
        background-position: center center;
        background-size: cover;

        @include tablet {
            min-height: 50rem;
        }

        .contenido-header {
            @include tablet {
                min-height: 50rem;
            }
        }
    }
}
.header-container-img {
    position: initial;
    width: 100%;
    @include tablet {
        position: absolute;bottom: 0;width: 100%;
        
    }
}
.contenido-header {
    display: flex;
    flex-direction: column;
    
   // justify-content: space-between;
   
.header-container-center {
    margin-top: 4rem;
    text-align: center;
    .header-container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        
        
        
        img {
            width: 200px;
        }
    }
    display: block;
    //min-height: 60rem;
    //align-items: center;
    h1 {
        text-align: center;
        color: $blanco;
        font-weight: 400;
        line-height: 1;
        //max-width: 60rem;
    }
    p {
        text-align: center;
        color: white;
    }
}

    
}

.section-double-bg {
    position: relative;
    height: auto !important;
    overflow: hidden;
}

.background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    @include tablet {
        justify-content: space-between;
        display: flex;
        min-height: 50rem;
    }
    
}
.background-container img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    @include tablet {
        height: 100%;
        width: 50%;
        
    }
}

.section-content {
    position: relative;
    z-index: 1;
    padding: 2rem;
    color: white;
}