.contenido-nosotros {

    @include tablet {
        @include grid(2, 2rem);
    }
}
.texttransfer {
    width: 100%;
    @include tablet {
        width: 50%;
        
    }
}

.texto-nosotros blockquote {
    font-weight: $black;
    font-size: 2.2rem;
    margin: 3rem 0 0 0;
    padding-left: 1rem 0 3rem 0;
}
.about-container {
    padding: 2rem;
    display: block;
   // align-items: center;
    img {
        display: inline !important;
    }
    @include tablet {
        display: flex;
    }
    
}
.signal-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding:2rem;
    
    //display: block;
    
    display: flex;
    
    flex-direction: column;
    @include tablet {
        flex-direction: row;
    }
}

.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 97%;
    iframe {
        height: 20rem;
        @include tablet {
            height: 95%;

        }
    }
    
   
    
}