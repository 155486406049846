.imagen-contacto {
    background-image: url(../img/tobring.jpg);
}

.captcha-box {
    display: flex;
    justify-content: left;
    width: 100%;
    .captcha {
    width: auto !important;
    }
}

.imagen-contacto {
    background-position: center center;
    background-size: cover;
    margin: 3rem 0 0 0;
    padding: $separacion * 2 0;
   // display: flex;
   .img-contact-box {
    display: flex;
    align-items: center;
   }
    flex-direction: column;
    align-items: center;
   // .img-contacto-texto {
   //     margin: 0 2rem;
  //  }
    h2 {
        font-size: 4rem;
        font-weight: $black;
        color: $blanco;
        text-align: left;
        line-height: 1.4;
    }
    p {
        font-size: 1.8rem;
        color: $blanco;
        text-align: left;
        margin: 0;
    }
    span {
        height: 100%;
        color: $verde;
    }
}
.contact-container {
    display: block;
    @include tablet {
        display: flex;
    }
    p {font-size: 1.6rem;}
    h2 {}
    h5 {}
    span {

    }
}