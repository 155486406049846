
@include tablet {
    .iconos-nosotros {
        @include grid(3, 2rem);
    }
    
}

.img-contacto-texto {
    max-width: 30rem;
    margin: 5rem !important;
    background-color: #00000071;
    padding: 1rem;
}
.icono {
    text-align: center;
    
    .container-about-img {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    img {
        //height: 10rem;
        width: 10rem;
    }
    h3 {
        text-transform: uppercase;
    }
}