.boton-amarillo {
    @include boton($amarillo, inline-block);
}

.boton-amarillo-block {
    @include boton($amarillo, block);
}

.boton-verde {
    @include boton(rgb(153, 204, 0), inline-block);
}
.btnWhatsapp{
    width:70px;
    height:70px;
    bottom: 5px; 
    left: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: fixed;
    z-index: 10;
    text-align: center;
    font-size: 25px;
    display: block;
    border-radius: 70px;  
   
  }