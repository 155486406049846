.derecha {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
        align-items: flex-end;
    }

    .dark-mode-boton {
        width: 3rem;
        margin-top: 3rem;
        margin-bottom: 2rem;
        filter: invert(100%);

        &:hover {
            cursor: pointer;
        }

        @include tablet {
            margin-top: 0;
        }
    }
}

.barra {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;margin-bottom: 1rem;
    @include tablet {
        flex-direction: row;
        justify-content: space-between;
        //align-items: flex-end;
    }
    img {
        max-width: 10rem;
    }
}

.navegacion {

    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: visibility .3s ease-in-out, opacity .5s linear, height .5s linear; 

    @include tablet {
        display: flex;
        align-items: center;
        opacity: 1;
        visibility: visible;
    }
    
    a {
        color: $blanco;
        text-decoration: none;
        font-size: 2.2rem;
        display: block;
        text-align: center;

        @include tablet {
            margin-right: 2rem;
            font-size: 1.8rem;

            &:last-of-type {
                margin-right: 0;
            }
        }

        &:hover {
            color: $verde;
        }
    }
}


/** Menu de hamburguesa **/
.mobile-menu {
    @include tablet {
        display: none;
    }

    img {
        width: 5rem;
    }
}

.mostrar {
    visibility: visible;
    opacity: 1;
    height: auto;
}



.dropdown {
   
    margin-right: 0rem;
    overflow: hidden;
    text-align: center;
    overflow-y: auto !important;
    a {
        
    }
  }
  
  .dropdown .dropbtn {
      margin: 0 0 0 0;
      text-align: center;
      //width: 100%;
      border: none;
      outline: none;
    color: white;
    //padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    font-size: 2.2rem;
}
@include tablet {
    .dropdown .dropbtn {
        font-size: 1.8rem;
        margin-right: 2rem !important;
    }
}

.navbar a:hover, .dropdown:hover .dropbtn {
 // background-color: $verde;
 color: $verde;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  //opacity: 0.9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  overflow: none;
}
.dropdown-lang-content {
  display: none;
  position: absolute;
  background-color: transparent;
  //padding: 5px;
  //opacity: 0.9;
  min-width: 32px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  overflow: none;
  
}
.show {
    display: block !important;
}
.hidden {
    display: none !important;
}
.dropdown-content a {
  float: none;
  color: black;
  padding: 6px 12px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 17px;
}
.dropdown-content button {
  float: none;
  color: black;
  padding: 10px 12px !important;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 17px;
  
}

.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown-content button:hover {
  background-color: #ddd !important;
  color: $verde;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.language {
    //color: #fff;
    position: absolute;
    float: right;
    top: 0px;
    right: 5px;
   // font-size: 24px;
    z-index: 11;
}
