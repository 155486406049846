
.popupbg {
    display: none;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    z-index: 9999;
    width: 100%;
    height: 100%;
    button {
        
        display: block;
        width: 100%;
    }
    p {
        text-transform: uppercase;
    }
}

  
  /* The popup form - hidden por default */
  .form-popup {
  
    background-color: #000000e0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    
    padding: 10px;
    
    z-index: 100;
    
    min-width: 85%;
    @include tablet {
        min-width: 55%;
    }
  }
  
  /* Add styles to the form container */
  .form-container {
    width: 100%;
    padding: 10px;
    
    background-color: rgba(255, 255, 255, 0.247);
    h3 {
        color: rgb(255, 255, 255);
        line-height: 1;
        margin-top: 5px;
    }
    label, 
    .date,
    p {
        color: rgb(255, 255, 255);
        text-align: left;
        
    }
    p {
        margin: 0;
        line-height: 1;
    }
    .date {padding: 1rem;}
    button {
        width: 100%;
    }
    .datelodge-container {
        @include grid(1, 0rem);
    }
    input {
        margin-bottom: 4px;
    }
    
  }
  .form {
    
    padding: 0rem;

    @include desktop {
        padding: 0rem;
        padding-right: 1rem;
    }
    p {
        font-size: 1.4rem;
        color: $blanco;
        margin: 0rem 0 0 0;
    }
    legend {
        font-size: 2rem;
        color: $blanco;
    }
    label {
        font-weight: $bold;
        text-transform: uppercase;
        display: block;
        color: white;
    }
    input:not([type="submit"]), textarea, select {
        padding: 1rem;
        display: block;
        width: 100%;
        background-color: $blanco;
        border: 1px solid $gris;
        border-radius: 1rem;
        margin-bottom: 0rem;
    }
    
    textarea {
        height: 20rem;
    }
    .form-contact {
        max-width: 30rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    input[type="radio"] {
        margin: 0;
        width: auto;
    }
}
#inquiry-title-id {
    color: white;
}
