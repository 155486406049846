.bg1 {
    background-image: url(../img/g1.jpg);
    
    background-size: cover;
    background-position: center center;
    
}
.rt33 {
    background-image: url(../img/rt33.jpg);
    
    background-size: cover;
    background-position: center center;
    
}
.bgp {
    background-image: url(../img/fpp.JPG);
    
    background-size: cover;
    background-position: center center;
    
}
.transfer {
    background-image: url(../img/tf2.png);
    
    background-size: cover;
    background-position: center center;
    
}
.srf1 {
    background-image: url(../img/srf1.JPG);
    
    background-size: cover;
    background-position: center center;
    
}
.srf4 {
    background-image: url(../img/srf4.JPG);
    
    background-size: cover;
    background-position: center center;
    
}
.bg1ra {
    background-image: url(../img/g4.jpg);
    
    background-size: cover;
    background-position: center center;
    
}
.bg2 {
    background-image: url(../img/g2.jpg);
    background-size: cover;
    background-position: center center;
}
.bg3 {
    background-image: url(../img/g3.jpg);
    background-size: cover;
    background-position: center center;
}
.bg4 {
    background-image: url(../img/rc41.jpg);
    background-size: cover;
    background-position: center center;
}
.bg5 {
    background-image: url(../img/g5.jpg);
    background-size: cover;
    background-position: center center;
}
.bg6 {
    background-image: url(../img/g6.jpg);
    background-size: cover;
    background-position: center center;
}
.bg7 {
    background-image: url(../img/g7.jpg);
    background-size: cover;
    background-position: center center;
}
.bg8 {
    background-image: url(../img/g8.jpg);
    background-size: cover;
    background-position: center center;
}
.bg14 {
    background-image: url(../img/rc42.jpg);
    background-size: cover;
    background-position: center center;
}
.bg5-1 {
    background-image: url(../img/bird_header.png);
    background-size: cover;
    background-position: center center;
}

.bgy1 {
    background-image: url(../img/yoga-1.jpg);
    background-size: cover;
    background-position: center center;
}
.bdw1 {
    background-image: url(../img/bdw1.jpg);
    background-size: cover;
    background-position: center center;
}
.bgy2 {
    background-image: url(../img/yoga-2.jpg);
    background-size: cover;
    background-position: center center;
}
.bgy3 {
    background-image: url(../img/yoga-3.jpg);
    background-size: cover;
    background-position: center center;
}
.bgy4 {
    background-image: url(../img/yoga-4.jpg);
    background-size: cover;
    background-position: center center;
}
.bgy2 {
    background-image: url(../img/yoga-2.jpg);
    background-size: cover;
    background-position: center center;
}
.bgy3 {
    background-image: url(../img/yoga-3.jpg);
    background-size: cover;
    background-position: center center;
}
.bghn1 {
    background-image: url(../img/hn1.png);
    background-size: cover;
    background-position: center center;
}
.bghn2 {
    background-image: url(../img/hn2.jpg);
    background-size: cover;
    background-position: center center;
}
.bghn3 {
    background-image: url(../img/hn3.jpg);
    background-size: cover;
    background-position: center center;
}
.bginf1 {
    background-image: url(../img/duckie1.jpg);
    background-size: cover;
    background-position: center center;
}
.bginf2 {
    background-image: url(../img/duckie2.jpg);
    background-size: cover;
    background-position: center center;
}
.bginf3 {
    background-image: url(../img/what-to-bring-if.jpeg);
    background-size: cover;
    background-position: center center;
}

.mb1 {
    background-image: url(../img/mb1.jpg);
    background-size: cover;
    background-position: center center;
}
.mb2 {
    background-image: url(../img/mb2.jpg);
    background-size: cover;
    background-position: center center;
}
.mb3 {
    background-image: url(../img/mb3.jpg);
    background-size: cover;
    background-position: top top;
}
.tf1 {
    background-image: url(../img/tf11.jpg);
    background-size: cover;
    background-position: bottom bottom;
}
.tk1 {
    background-image: url(../img/trekking1.jpg);
    background-size: cover;
    background-position: center center;
}
.tk2 {
    background-image: url(../img/trekking2.jpg);
    background-size: cover;
    background-position: center center;
}
.tk3 {
    background-image: url(../img/trekking3.jpg);
    background-size: cover;
    background-position: center center;
}
.rf1 {
    background-image: url(../img/rtf2.jpg);
    background-size: cover;
    background-position: center center;
}
.rf2 {
    background-image: url(../img/rtf3.jpg);
    background-size: cover;
    background-position: center center;
}
.rf3 {
    background-image: url(../img/rtf1.jpg);
    background-size: cover;
    background-position: center center;
}
.tb1 {
    background-image: url(../img/tubbing1.jpg);
    background-size: cover;
    background-position: center center;
}
.tb2 {
    background-image: url(../img/tubbing2.jpg);
    background-size: cover;
    background-position: center center;
}
.tb3 {
    background-image: url(../img/tubbing3.jpg);
    background-size: cover;
    background-position: center center;
}

.inicio {
    background-image: url(../img/a1.jpg);
    background-size: cover;
    background-position: center center;
}
.contact-img {
    background-image: url(../img/contactus.JPG);
    background-size: cover;
    background-position: center center;
}
.c3header {
    background-image: url(../img/c3header.JPG);
    background-size: cover;
    background-position: center center;
}
.wtbc3 {
    background-image: url(../img/wtbc3.JPG);
    background-size: cover;
    background-position: center center;
}
.wtbc3 {
    background-image: url(../img/wtbc4.JPG);
    background-size: cover;
    background-position: center center;
}
.hsheader {
    background-image: url(../img/hs1.JPG);
    background-size: cover;
    background-position: top top;
}
.cs1 {
    background-image: url(../img/cs1.JPG);
    background-size: cover;
    background-position: top top;
}
.cs2 {
    background-image: url(../img/cs2.JPG);
    background-size: cover;
    background-position: center center;
}
.ve1 {
    background-image: url(../img/cs2.JPG);
    background-size: cover;
    background-position: top top;
}
.cc1 {
    background-image: url(../img/cc1.JPG);
    background-size: cover;
    background-position: top top;
}
.cc4 {
    background-image: url(../img/cc4.JPG);
    background-size: cover;
    background-position: top top;
}
.rep1 {
    background-image: url(../img/rep1.JPG);
    background-size: cover;
    background-position: center center;
}
.rep4 {
    background-image: url(../img/rep4.JPG);
    background-size: cover;
    background-position: center center;
}
.fv2 {
    background-image: url(../img/fv2.JPG);
    background-size: cover;
    background-position: center center;
}
.fv4 {
    background-image: url(../img/fv4.JPG);
    background-size: cover;
    background-position: center center;
}