@include tablet {
    .contenedor-anuncios {
        @include grid(3, 2rem);
    }
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body { font-family: sans-serif; }
.products {
    padding: 1rem;
    @include tablet {
        padding: 0;
        display: flex;
        justify-content: space-between;
        //text-align: left;
        //justify-content: space-between;
    }

}

.product-item-container {
    @include tablet {
        display: flex;
        justify-content: space-between;

    }
}
.product {
    width: 100%;
    display: block !important;
}
.gallery {
    background: #EEE;
}

.product-container {
    background-color: rgba($color: #30752d, $alpha: 0.5);
}
.gallery-cell {
  width: 66%;
  height: 200px;
  margin-right: 10px;
  background: #8C8;
  counter-increment: gallery-cell;
}

/* cell number */
.gallery-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.anuncio {
    background-color: lighten($gris, 5);
    border: 1px solid darken($gris, 10);
    margin-bottom: 2rem;
.btn-product-2 {
    background-color: transparent !important;
    border: 1px solid darken($verde, 10);
    color: $verde;
    border-radius: 0 30px 30px 0;
    &:hover {
        background-color: $verde !important;
        color: $blanco;
    }
}
.btn-product-1 {
    background-color: transparent !important;
    border-top: 1px solid darken($amarillo, 10);
    border-bottom: 1px solid darken($amarillo, 10);
    border-left: 1px solid darken($amarillo, 10);
    color: $amarillo;
    border-radius: 30px 0 0 30px;
    &:hover {
        background-color: $amarillo !important;
        color: $blanco;
    }
   
}
    .contenido-anuncio {
        padding: 2rem;
    }
    a {
        font-size: 1.5rem !important;
    }
    h3, p {
        margin: 0;
        font-weight: 400;
    }
    
}
.precio {
    color: $verde;
    font-weight: 700;
    font-size: 2.2rem;
    text-align: center;
  
}

.iconos-caracteristicas {
    display: flex;
    list-style: none;
    padding: 0;
    max-width: 40rem;

    li {
        flex: 1;
        display: flex;

        img {
            flex:  0 0 3rem;
            margin-right: 1rem;
        }
        p {
            font-weight: $black;
        }
    }
}
.product-container {
    width: 100%;
    .line-height-17 {
        line-height: 1.7rem;
    }
    .margin-top-1 {
        margin-top: 1rem;
    }
    .text-center {
        text-align: center !important;
    }
    h4 {
        text-align: center;
        margin: 0;
    }

    p {
        margin: 0;
        text-align: center;
        
    }
    .product-item-includes {
        p {
            @include tablet {
                text-align: left;
                //justify-content: space-between;
            }
        }
    }
    .product-item {
        width: 100%;
        padding: 1rem;
    }
    @include tablet {
        display: flex;
        //justify-content: space-between;
    }
    button {
        max-width: 30rem;
    }
}
.contenedor-anuncios-seccion {
    background-color: rgb(238, 238, 238);
}
.br-no-mobil {
    display: inline-block;
    @include tablet {
        display: none;
        

    }
}
.doblue-img-container {
    
}
.product-item-container {
h3 {}
}
.rt {
    .rtc {
        width: 100%;
        @include tablet {
            width: 40%;
        }
    }
    
}
